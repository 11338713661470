<template>
    <router-link
        :to="{
            name: 'intellectualPropertyDetail',
            query: {
                id: info.id
            }
        }"
        class="IntellectualProperty"
    >
        <el-image :src="getImg(info.img)" fit="cover"></el-image>

        <div class="content">
            <div class="title">{{ info.name }}</div>
            <div class="info">
                <div class="info-item">
                    <span class="text1">申请号：</span>
                    <span class="text2">{{ info.code }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">行业分类：</span>
                    <span class="text2">{{ info.setting.name }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">申请日:</span>
                    <span class="text2">{{ info.applyTime }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">专利权人：</span>
                    <span class="text2">{{ info.owner }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">发明人：</span>
                    <span class="text2">{{ info.inventor.join(';') }}</span>
                </div>
            </div>
        </div>
        <!-- <div class="btn">
            <el-button size="small"> 详情 </el-button>
        </div> -->
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
import { typeOptions } from '../../utils/variables';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        type() {
            let info = typeOptions.find(item => {
                return item.value === this.info.type;
            });
            return info ? info.label : '';
        }
    },
    mixins: [comEvent]
};
</script>

<style lang="less" scoped>
/deep/ .el-button {
    width: 130px;
    height: 36px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #ff8700;
    color: #ff8700;
    text-align: center;
    margin-right: 20px;
}
.IntellectualProperty {
    border-bottom: 1px solid @bg;
    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81,81,81,0.1);
        border: 1px solid #01A041;
    }
    overflow: hidden;
    .flex();
    padding: 20px 0 20px 20px;

    .el-image {
        width: 122px;
        height: 182px;
        flex-shrink: 0;
    }

    .content {
        padding: 20px;
        flex-grow: 1;
        padding-right: 80px;
        overflow: hidden;
        .title {
            font-size: 16px;
            font-weight: bold;
            color: #292c33;
            line-height: 22px;
        }

        .info {
            margin-top: 20px;
            .flex();
            flex-wrap: wrap;
            .info-item {
                width: 50%;
                padding-right: 50px;
                box-sizing: border-box;
                line-height: 32px;
                .flex();

                .text1 {
                    font-size: 13px;
                    color: #939599;
                    display: inline-block;
                    min-width: 65px;
                    flex-shrink: 0;
                }

                .text2 {
                    font-size: 13px;
                    color: #00684f;
                    margin-left: 10px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>

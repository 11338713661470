<template>
    <sort-list
        v-model="list"
        :defaultQuery="defaultQuery"
        :defaultSort="defaultSort"
        @init="init"
        ref="list"
        url="/intellectualProperty/all"
        :filters="filters"
    >
        <template v-for="item in list">
            <intellectual-property-row :key="item.id" :info="item"></intellectual-property-row>
        </template>
    </sort-list>
</template>

<script>
import IntellectualPropertyRow from '../../components/list/IntellectualPropertyRow.vue';
import { typeOptions, intePropLawStatus } from '../../utils/variables';
import SortList from '../../components/page/SortList.vue';
export default {
    components: { SortList, IntellectualPropertyRow },
    created() {
        this.init();
    },
    data() {
        return {
            industryClassOptions: [],
            list: [],
            defaultQuery: {
                category: 'IntellectualProperty'
            },
            defaultSort: {
                sort: 'id,desc'
            },
            typeOptions,
            intePropLawStatus
        };
    },
    computed: {
        filters() {
            return [
                {
                    name: '专利区分',
                    key: 'typeId',
                    type: 'select',
                    list: [...this.typeOptions],
                    default: this.$route.query.typeId ? Number(this.$route.query.typeId) : ''
                },
                {
                    name: '行业分类',
                    key: 'industryClass',
                    type: 'select',
                    list: [...this.industryClassOptions]
                },
                {
                    name: '法律状态',
                    key: 'lawStatusId',
                    type: 'select',
                    list: [...this.intePropLawStatus]
                },

                {
                    name: '专利名称',
                    key: 'name'
                },
                {
                    name: '专利权人',
                    key: 'owner'
                },
                {
                    name: '发明人',
                    key: 'inventor'
                },
                {
                    name: '申请号',
                    key: 'code'
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 13 })
                .then(res => {
                    if (res.length > 0) {
                        this.industryClassOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped></style>
